var PAYMENTS_FEE_CONST = {
  CREDIT_CARD_FEE: '3%',
  INTERNATIONAL_CARD_FEE: '4.1%',
  ACH_FEE: 'FREE',
  APPLICATION_FEE_CC: '$59.90'
};
var APPLICATION_REQUIREMENTS = {
  MONTHS_RENT: 36,
  COSIGNER_MINIUM_MONTHS_RENT: 72
};
var HOME_FEES = {
  MANAGEMENT_FEES: 8,
  PLACEMENT_FEES: 50
};
var PAYMENT_WAIT_TIMES = {
  INSTANT_VERIFICATION: '1 minute',
  MANUAL_VERIFICATION: '2-3 days'
};

export { APPLICATION_REQUIREMENTS, HOME_FEES, PAYMENTS_FEE_CONST, PAYMENT_WAIT_TIMES };
